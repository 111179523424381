<template>
  <div>
    <global-header></global-header>
    <search-bar></search-bar>
    <div class="sctp-container mg-tb10">
      <div class="sctp-bg-white pad15 flex" style="min-height: 70vh;">
              <div>
                <el-image :src="limitConfig.imgUrl"
                  fit="cover"
                  style="width: 300px;height: 500px;"
                ></el-image>
              </div>
        <div class="pad-lr20">
          <h1>温馨提醒：</h1>
          <div class="fz-18" style="text-indent: 2em;">
            您的账号因{{ limitConfig.info && limitConfig.info.limitReason || '违规' }}被限制登录，请在{{
              dateToString(limitConfig.info && limitConfig.info.limitDate || null,
                'yyyy年MM月dd日 HH时mm分ss秒')
            }}以后再访问！如有疑问，请提交意见反馈，感谢您的理解。
          </div>
        </div>
              <div style="width: 500px;border: 1px solid #eeeeee" class="pad15">
                <h1 class="mg-b10">账号违规情况说明</h1>
                <div class="fz-16 sctp-lh-medium">
                  <template v-for="(reason, index) in limitConfig.reasonList">
                    <div>{{ index + 1 }}、{{ reason.reason }}</div>
                  </template>
                </div>
              </div>
      </div>
    </div>
    <global-footer></global-footer>
  </div>
</template>

<script>
import {user} from '@/apis/index'

export default {
  name: "IpLimitAccess",
  components: {
    GlobalHeader: () => import('@CMP/common/GlobalHeader'),
    SearchBar: () => import('@PAGE/home/HomeSearchBar'),
    GlobalFooter: () => import('@CMP/common/GlobalFooter')
  },
  data() {
    return {
      limitConfig: {
        info: null,
        imgUrl: null,
        reasonList: [],
        loadLimitInfo: () => {
          user.limitAccessInfo({
            userId: this.user.userId,
          }).then(res => {
            let {data, reasonList, imgUrl} = res;
            // if (!data || !data.limitAccess) {
            //   this.goPage('/login');
            //   return;
            // }
            this.limitConfig.reasonList = reasonList;
            if (imgUrl) {
              this.limitConfig.imgUrl = imgUrl;
            }
            this.limitConfig.info = data;
          })
        }
      }
    }
  },
  beforeMount() {
    this.limitConfig.loadLimitInfo()
  }
}
</script>

<style scoped lang="scss">

</style>
